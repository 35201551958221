@import '../../variable.less';

.home-about-container {
    .image-area {
        position: relative;
        z-index: 9;
        height: 450px;

        .about-image {
            position: relative;
            top: 0;
            right: 45px;
            float: right;
            z-index: 9;
            img {
                width: 370px;
                border-radius: 5px;
            }
        }
        .about-image:nth-child(2) {
            position: absolute;
            top: 50px;
            left: 0;
            z-index: 8;
            float: left;
            text-align: left;
        }
        .shape-1 {
            position: absolute;
            right: 150px;
            transform: translate3d(0px, 0px, 0px) rotate(0.0001deg);
            transform-style: preserve-3d;
            backface-visibility: hidden;
            pointer-events: none;
            span {
                width: 200px;
            }
        }
    }
    .home-about-what-we-do {
        padding-left: 40px;
        padding-right: 40px;
        .home-about-what-we-do-btn {
            margin-top: 30px;
        }
    }
    @media @phone {
        .image-area {
            height: 350px;
            .about-image {
                left: 0;
                width: 100%;
                img {
                    width: 100%;
                }
            }
            .about-image:nth-child(2) {
                display: none;
            }
        }
        .home-about-what-we-do {
            padding-left: 0;
            padding-right: 0;
            margin-top: 20px;
            margin-bottom: 20px;
            .home-about-what-we-do-btn {
                text-align: center;
            }
            .section-title2-container {
                h4 {
                    margin-top: 25px;
                    margin-bottom: 25px;
                }
            }
            h2 {
                text-align: center;
            }
            span {
                text-align: center;
                display: block;
            }
        }
    }
}
@primary-color: #36394D;@secondary-color: #F8DD2E;@tertiary-color: #CEBC81;@quaternary-color: #B19F9E;@footer-color: #2a2a2a;@footer-darker-color: #1f1f1f;@background-color: #f0f2f5;@grey-color: #7b7894;