@import '../variable.less';

.contact-info-container {
    display: flex;
    align-items: center;
    .contact-info-link {
        font-size: 18px
    }
    .contact-info-link:hover {
        font-weight: 600;
    }
    svg line, svg polyline, svg polygon {
        stroke: @primary-color;
    }
    svg {
        font-size: 40px;
        margin-right: 30px;
    }
    @media @phone {
        
    }
}
@primary-color: #36394D;@secondary-color: #F8DD2E;@tertiary-color: #CEBC81;@quaternary-color: #B19F9E;@footer-color: #2a2a2a;@footer-darker-color: #1f1f1f;@background-color: #f0f2f5;@grey-color: #7b7894;