@import '../../variable.less';

.home-container {
    padding: 50px;
    @media @phone {
        padding: 20px;
    }
}


@primary-color: #36394D;@secondary-color: #F8DD2E;@tertiary-color: #CEBC81;@quaternary-color: #B19F9E;@footer-color: #2a2a2a;@footer-darker-color: #1f1f1f;@background-color: #f0f2f5;@grey-color: #7b7894;