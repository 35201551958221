@screen-xs-min:     480px;
@screen-sm-min:     768px;
@screen-md-min:     992px;
@screen-lg-min:     1200px;

@screen-xs-max:     (@screen-sm-min - 1);
@screen-sm-max:     (@screen-md-min - 1);
@screen-md-max:     (@screen-lg-min - 1);

@phone:             ~"only screen and (max-width: @{screen-xs-min})";
@phone-strict:      ~"only screen and (min-width: @{screen-xs-min}) and (max-width: @{screen-xs-max})";
@tablet:            ~"only screen and (min-width: @{screen-sm-min})";
@tablet-strict:     ~"only screen and (min-width: @{screen-sm-min}) and (max-width: @{screen-sm-max})";
@desktop:           ~"only screen and (min-width: @{screen-md-min})";
@desktop-strict:    ~"only screen and (min-width: @{screen-md-min}) and (max-width: @{screen-md-max})";
@large:             ~"only screen and (min-width: @{screen-lg-min})";

@header-height:     90px;
@header-mobile-height: 64px;

@keyframes headerSlideDown {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}