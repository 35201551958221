@import '../variable.less';

@footer-height: 250px;
@footer-copyright-height: 50px;
@footer-padding: 50px;

.footer-container {
    height: @footer-height;
    .footer-main-content {
        display: flex;
        justify-content: space-between;
        height: @footer-height - @footer-copyright-height;
        background-color: @footer-color;
        padding: @footer-padding;
        .footer-main-content-col1 {
            flex: 1;
            img {
                content:url(https://public-dns.s3.ca-central-1.amazonaws.com/net.web4biz/logo_h_gray_full.png);
                width: 250px;
            }
        }
        .footer-main-content-col2 {
            display: flex;
            justify-content: space-between;    
            flex: 1;
        }
        .footer-main-content-2 {
            .ant-typography {
                font-size: 16px;
                color: @background-color;
                margin-bottom: 5px;
                cursor: pointer;
            }
        }
        .footer-main-content-3 {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .footer-contact {
                .ant-typography {
                    font-size: 16px;
                    color: @background-color;
                    margin-bottom: 5px;
                    
                    > span[role=img] {
                        margin-right: 7px;
                    }
                }
            }
            
            .footer-social {
                .ant-image {
                    width: 32px;
                    margin-right: 5px;
                    cursor: pointer;
                }
            }
        }
    }
    .footer-copyright {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: @footer-copyright-height;
        background-color: @footer-darker-color;
        color: @grey-color;
        padding-left: @footer-padding;
        padding-right: @footer-padding;
    }
    @media @phone {
        height: auto;
        .footer-main-content {
            padding: 20px;
            height: auto;
            .footer-main-content-col1 {
                flex: 1;
                img {
                    content:url(https://public-dns.s3.ca-central-1.amazonaws.com/net.web4biz/logo_v_gray_full.png);
                    width: 110px;
                }
            }
            .footer-main-content-col2 {
                flex-direction: column;
                justify-content: center;
            }
            .footer-main-content-2 {
                display: none;
            }
        }
        .footer-copyright {
            flex-direction: column;
            justify-content: center;
        }
    }
}
@primary-color: #36394D;@secondary-color: #F8DD2E;@tertiary-color: #CEBC81;@quaternary-color: #B19F9E;@footer-color: #2a2a2a;@footer-darker-color: #1f1f1f;@background-color: #f0f2f5;@grey-color: #7b7894;