@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import (css) url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;600;900&display=swap');
@import (css) url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap');
@import './variable.less';

body {
    margin: 0;
    font-family: 'Mulish', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media @phone {
    h1.ant-typography {
        font-size: 32px;
    }
    .ant-typography + h2.ant-typography {
        font-size: 28px;
        margin-top: 0;
    }
    .ant-typography + h4.ant-typography {
        font-size: 18px;
        margin-top: 0;
    }
}    



@primary-color: #36394D;@secondary-color: #F8DD2E;@tertiary-color: #CEBC81;@quaternary-color: #B19F9E;@footer-color: #2a2a2a;@footer-darker-color: #1f1f1f;@background-color: #f0f2f5;@grey-color: #7b7894;