@import '../../variable.less';

.home-testimonial-container {
    padding-top: 50px;
    padding-bottom: 50px;
    .testimonial-slider {
        margin-bottom: 32px;
    }
    @media @phone {
        padding: 20px;
        .testimonial-slider {
            margin-bottom: 0;
        }
    }
}
@primary-color: #36394D;@secondary-color: #F8DD2E;@tertiary-color: #CEBC81;@quaternary-color: #B19F9E;@footer-color: #2a2a2a;@footer-darker-color: #1f1f1f;@background-color: #f0f2f5;@grey-color: #7b7894;