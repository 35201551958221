@import '../variable.less';

.language-selector-container {
    color: white;
    margin-left: 30px;
    font-size: 16px;
    text-transform: uppercase;
    
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @media @phone {
        
    }
}

.language-selector-container:hover {
    cursor: pointer;
}
@primary-color: #36394D;@secondary-color: #F8DD2E;@tertiary-color: #CEBC81;@quaternary-color: #B19F9E;@footer-color: #2a2a2a;@footer-darker-color: #1f1f1f;@background-color: #f0f2f5;@grey-color: #7b7894;