@import '../variable.less';

.pricing-card-container {
    .pricing-card-ptline {
        width: 100%;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 5px 5px 0 0;
    }
    .pricing-card-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 30px;
        & > svg {
            font-size: 28px;
        }
        .pricing-card-price {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 63px;
            margin-bottom: 50px;
            & > div {
                display: flex;
                align-items: baseline;
                & > .pricing-card-price-title {
                    margin-right: 5px;
                    margin-bottom: 0;
                }
            }
        }
    }
    @media @phone {
        
    }
}
@primary-color: #36394D;@secondary-color: #F8DD2E;@tertiary-color: #CEBC81;@quaternary-color: #B19F9E;@footer-color: #2a2a2a;@footer-darker-color: #1f1f1f;@background-color: #f0f2f5;@grey-color: #7b7894;