@import '../variable.less';

.header-container {
    .ant-layout-header {
        position: absolute;
        top: 0;
        background-color: transparent;
        height: @header-height;
        width: 100%;
        z-index: 100;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid hsla(0,0%,100%,.5);
    
        .header-pc-view {
            display: flex;
            width: -webkit-fill-available;
            img {
                content:url(https://public-dns.s3.ca-central-1.amazonaws.com/net.web4biz/logo_h_white.png);
                width: 240px;
            }
        }
        .header-mobile-view {
            display: none;
        }
    
        .ant-menu-horizontal {
            border-bottom: none !important;
        }
    
        .ant-menu {
            color: @background-color;
            display: flex;
            justify-content: flex-end;
            background-color: inherit;
            flex: 1;
        }
    
        .ant-menu-item {
            display: flex;
            justify-content: center;
            width: 100px;
        }
    
        .ant-menu-item-selected {
            color: @secondary-color !important;
        }
    
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
            color: white;
        }
    
        .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
            border-bottom: none !important;
        }
        .ant-menu-horizontal > .ant-menu-item::after,
        .ant-menu-horizontal > .ant-menu-submenu::after {
          border-bottom: none !important;
          transition: none !important;
        }
    
        .ant-menu-title-content {
            font-size: 16px;
            font-weight: 600;
            min-width: 100px;
            text-align: center;
        }
    }

    @media @tablet {
        .ant-layout-header {
            &.is-sticky {
                background-color: @footer-color;
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                z-index: 1000;
                animation: headerSlideDown 0.95s ease forwards;
                border-bottom: 0;
            }
        }
    }

    @media @phone {
        // height: @header-mobile-height;      
        .ant-layout-header {
            border-bottom: none;
            padding: 0 20px;
            position: inherit;
            background-color: @footer-color;
            height: @header-mobile-height;
            .header-pc-view {
                display: none;
            }
            .header-mobile-view {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                span[role=img] {
                    font-size: 24px;
                }
                span {
                    color: black;
                }
            }
        }
    }
}
@primary-color: #36394D;@secondary-color: #F8DD2E;@tertiary-color: #CEBC81;@quaternary-color: #B19F9E;@footer-color: #2a2a2a;@footer-darker-color: #1f1f1f;@background-color: #f0f2f5;@grey-color: #7b7894;