@import '../variable.less';

.our-service-container {
    padding-top: 50px;
    padding-bottom: 50px;
    .our-service-title {
        & > .ant-typography {
            text-align: center;
            margin: 0
        }
    }
    .our-service-content {
        margin: auto;
        margin-top: 50px;
        margin-bottom: 50px;
        max-width: 1000px;
        
    }
    @media @phone {
        padding-left: 20px;
        padding-right: 20px;
        .our-service-content {
            margin-bottom: 0px;
        }
    }
}
@primary-color: #36394D;@secondary-color: #F8DD2E;@tertiary-color: #CEBC81;@quaternary-color: #B19F9E;@footer-color: #2a2a2a;@footer-darker-color: #1f1f1f;@background-color: #f0f2f5;@grey-color: #7b7894;