@import '../variable.less';

.section-title2-container {
    h2 {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .sub-title {
        position: relative;
        padding-left: 80px;
        color: @grey-color;
    }
    .sub-title:before {
        position: absolute;
        content: "";
        width: 70px;
        height: 2px;
        background-color: @secondary-color;
        left: 0;
        bottom: 0;
    }
    @media @phone {
    
    }
}
@primary-color: #36394D;@secondary-color: #F8DD2E;@tertiary-color: #CEBC81;@quaternary-color: #B19F9E;@footer-color: #2a2a2a;@footer-darker-color: #1f1f1f;@background-color: #f0f2f5;@grey-color: #7b7894;