@import '../variable.less';

.section-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 50px;
    margin-bottom: 50px;
    .ant-typography {
        text-align: center;
    }
    h1 {
        margin-top: 0;
        margin-bottom: 0;
    }
    h4 {
        margin-top: 10px;
        margin-bottom: 0;
        max-width: 530px;
        color: @grey-color;
    }
    @media @phone {
        // margin-top: 20px;
        margin-bottom: 20px;
    }
}
@primary-color: #36394D;@secondary-color: #F8DD2E;@tertiary-color: #CEBC81;@quaternary-color: #B19F9E;@footer-color: #2a2a2a;@footer-darker-color: #1f1f1f;@background-color: #f0f2f5;@grey-color: #7b7894;