@import '../variable.less';

.about-container {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    @media @phone {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
}
@primary-color: #36394D;@secondary-color: #F8DD2E;@tertiary-color: #CEBC81;@quaternary-color: #B19F9E;@footer-color: #2a2a2a;@footer-darker-color: #1f1f1f;@background-color: #f0f2f5;@grey-color: #7b7894;