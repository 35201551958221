@import '../variable.less';

.rating-card-container {
    .review-card-title {
        display: flex;
        flex-direction: column;
        li {
            margin-left: 0;
        }
    }
    
    .ant-card-bordered .ant-card-cover {
        margin-top: 20px;
        text-align: center;
    }
    .ant-card-body {
        display: flex;
        justify-content: center;
    }
    .ant-image {
        display: flex;
        justify-content: center;
        padding-top: 50px;
        padding-bottom: 20px;
        img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }
    }
    @media @phone {
        
    }
}
@primary-color: #36394D;@secondary-color: #F8DD2E;@tertiary-color: #CEBC81;@quaternary-color: #B19F9E;@footer-color: #2a2a2a;@footer-darker-color: #1f1f1f;@background-color: #f0f2f5;@grey-color: #7b7894;