@import '../../variable.less';

.home-intro-video {
    position: relative;
    video {
        display: block;
    }
    .home-intro-mask {
        // background: linear-gradient(90deg,rgba(3,15,39,.7) 0,rgba(3,15,39,0));
        background-color: rgba(0, 0, 0, 0.4);
        // opacity: 0.5;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .ant-btn:hover, .ant-btn:focus {
        border-color: @secondary-color;
    }
    .home-intro-text {
        font-family: 'Roboto', sans-serif;
        max-width: 840px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: white;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50px;
        right: 0;
        
        .home-intro-text-title {
            font-size: 64px;
            line-height: 58px;
            font-weight: 600;
        }
        .home-intro-text-subtitle {
            font-size: 32px;
            font-weight: 300;
            margin-top: 15px;
        }
        .home-intro-text-content {
            font-size: 18px;
            font-weight: 300;
            margin-top: 15px;
        }
    }

    @media @phone {
        .home-intro-text {
            left: 15px;
            right: 15px;
            .home-intro-text-title {
                font-size: 26px;
                line-height: 26px;
            }
            .home-intro-text-subtitle {
                font-size: 16px;
                font-weight: 400;
                margin-top: 15px;
            }
            .home-intro-text-content {
                display: none;
            }
        }
    }
}
@primary-color: #36394D;@secondary-color: #F8DD2E;@tertiary-color: #CEBC81;@quaternary-color: #B19F9E;@footer-color: #2a2a2a;@footer-darker-color: #1f1f1f;@background-color: #f0f2f5;@grey-color: #7b7894;