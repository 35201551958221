@import '../variable.less';

.contact-form-container {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    .contact-form-contact {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        & > input {
            width: 49%;
        }
    }
    // .contact-form-items {
    //     > * {
    //         margin-bottom: 15px;
    //     }
    // }
    .contact-form-submit {
        margin-top: 20px;
        text-align: right;
        & > button {
            width: 150px;
        }
    }
    @media @phone {
        
    }
}
@primary-color: #36394D;@secondary-color: #F8DD2E;@tertiary-color: #CEBC81;@quaternary-color: #B19F9E;@footer-color: #2a2a2a;@footer-darker-color: #1f1f1f;@background-color: #f0f2f5;@grey-color: #7b7894;