@import '../variable.less';

.header-breadscrumbs-container {
    height: 340px;
    position: relative;
    .header-breadscrumbs-image {
        height: 100%;
        display: flex;
        align-items: flex-end;
        background-size: cover;
        background-position: center;
        .header-breadscrumbs-desc {
            display: flex;
            flex-direction: column;
            height: calc(100% - @header-height);
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 100;
            & > h1 {
                margin-bottom: 20px;
                font-weight: bold;
            }
            & * {
                color: @background-color;
                margin: 0;
            }
        }
    }
    .header-breadscrumbs-mask {
        background: linear-gradient(90deg, rgba(3, 15, 39, 0.7) 0, rgba(3, 15, 39, 0));
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
    }
    @media @phone {
        height: 200px;
        .header-breadscrumbs-image {
            .header-breadscrumbs-desc { 
                height: 100%;
                .ant-typography {
                    text-align: center;
                }
            }
        }
    }
}
@primary-color: #36394D;@secondary-color: #F8DD2E;@tertiary-color: #CEBC81;@quaternary-color: #B19F9E;@footer-color: #2a2a2a;@footer-darker-color: #1f1f1f;@background-color: #f0f2f5;@grey-color: #7b7894;